import { PracticeSlugFragment, TranslationDetailFragment } from '@docpace/shared-graphql/fragments'
import {
    ProviderDepartmentOptionItem,
    PDOCategories,
    ProviderDepartmentOptionDataType,
    TranslationType,
} from '@docpace/shared-ts-types'

interface AdminProviderDepartmentOptionRowsArgs {
    practiceFaqTranslations?: TranslationDetailFragment[]
    practiceLinkTranslations?: TranslationDetailFragment[]
    practiceDirectionsTranslations?: TranslationDetailFragment[]
    practiceContactTranslations?: TranslationDetailFragment[]
    practiceContentTranslations?: TranslationDetailFragment[]
    practiceSmsTranslations?: TranslationDetailFragment[]
    globalSmsTranslations?: TranslationDetailFragment[]
    practiceSlugs?: PracticeSlugFragment[]
}

export const makeAdminProviderDepartmentOptionRows = ({
    practiceFaqTranslations,
    practiceLinkTranslations,
    practiceDirectionsTranslations,
    practiceContactTranslations,
    practiceSmsTranslations,
    globalSmsTranslations,
    practiceSlugs
}: AdminProviderDepartmentOptionRowsArgs)=>{
    const translations = [...(practiceSmsTranslations??[]), ...(globalSmsTranslations??[])]
    return [
        {
            title: 'Disable Delay Calc',
            name: ProviderDepartmentOptionItem.appointmentsDisableDelayCalc,
            type: ProviderDepartmentOptionDataType.checkbox,
            categories: [
                PDOCategories.manager,
                PDOCategories.calculations,
            ],
        },
        {
            title: 'Anchor Autopause to SAT',
            name: ProviderDepartmentOptionItem.anchorAutoPauseToSuggestedArrivalTime,
            type: ProviderDepartmentOptionDataType.checkbox,
            categories:[
                PDOCategories.pausing,
                PDOCategories.calculations,
            ],
        },
        {
            title: 'SAT Max Drift (minutes)',
            name: ProviderDepartmentOptionItem.suggestedArrivalTimeMaxDriftMinutes,
            type: ProviderDepartmentOptionDataType.intNumber,
            categories:[
                PDOCategories.suggestedArrival,
                PDOCategories.patient,
                PDOCategories.messaging,
            ],
        },
        {
            title: 'Autopause After (minutes) (pre)',
            name: ProviderDepartmentOptionItem.autoPauseAfterMinutes,
            type: ProviderDepartmentOptionDataType.intNumber,
            categories:[
                PDOCategories.pausing,
                PDOCategories.calculations
            ],
        },
        {
            title: 'Force pause After (minutes) (pre)',
            name: ProviderDepartmentOptionItem.forcePauseAfterMinutes,
            type: ProviderDepartmentOptionDataType.intNumber,
            categories:[
                PDOCategories.pausing,
                PDOCategories.calculations
            ],
        },
        {
            title: 'Hide on Manager Site',
            name: ProviderDepartmentOptionItem.appointmentsHideOnManager,
            type: ProviderDepartmentOptionDataType.checkbox,
            categories:[
                PDOCategories.manager,
                PDOCategories.visibility,
            ],
        },
        {
            title: 'Exclude from Views and Queues',
            name: ProviderDepartmentOptionItem.excludeFromViewsAndQueues,
            type: ProviderDepartmentOptionDataType.checkbox,
            categories:[
                PDOCategories.manager,
                PDOCategories.receptionStatus,
                PDOCategories.visibility,
            ],
        },
        {
            title: 'Show Suggested Arrival',
            name: ProviderDepartmentOptionItem.showSuggestedArrivalTimeInVwr,
            type: ProviderDepartmentOptionDataType.checkbox,
            categories:[
                PDOCategories.patient,
                PDOCategories.visibility,
                PDOCategories.suggestedArrival,
            ],
        },
        {
            title: 'showCheckInButtonInVwr',
            name: ProviderDepartmentOptionItem.showCheckInButtonInVwr,
            type: ProviderDepartmentOptionDataType.checkbox,
            categories:[
                PDOCategories.patient,
                PDOCategories.visibility,
                PDOCategories.checkin,
            ],
        },
        {
            title: 'Display Latest SMS SAT',
            name: ProviderDepartmentOptionItem.patientSiteDisplayLatestSmsSuggestedArrivalTime,
            type: ProviderDepartmentOptionDataType.checkbox,
            categories:[
                PDOCategories.messaging,
                PDOCategories.suggestedArrival,
                PDOCategories.visibility,
            ],
        },
        {
            title: 'Patient Site Practice Slug',
            name: ProviderDepartmentOptionItem.patientSitePracticeSlugId,
            type: ProviderDepartmentOptionDataType.practiceSlug,
            practiceSlugs,
            categories:[
                PDOCategories.visibility,
                PDOCategories.patient,
            ],
        },
        {
            title: 'Min minutes between reminder texts',
            name: ProviderDepartmentOptionItem.minMinutesBetweenReminderTexts,
            type: ProviderDepartmentOptionDataType.intNumber,
            categories:[
                PDOCategories.messaging,
                PDOCategories.contact,
                PDOCategories.patient,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.googlePlaceId,
            title: 'Google Place',
            type: ProviderDepartmentOptionDataType.googlePlace,
            categories:[
                PDOCategories.locationAndDirections
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteShowLocation,
            title: 'Show Location',
            type: ProviderDepartmentOptionDataType.checkbox,
            categories:[
                PDOCategories.patient,
                PDOCategories.locationAndDirections,
                PDOCategories.visibility,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteDirectionsTranslationId,
            title: 'Directions t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations: practiceDirectionsTranslations,
            translationType: TranslationType.DIRECTIONS,
            categories:[
                PDOCategories.locationAndDirections,
                PDOCategories.patient,
                PDOCategories.translation,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteRemoteDirectionsTranslationId,
            title: 'Remote Directions t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations: practiceDirectionsTranslations,
            translationType: TranslationType.DIRECTIONS,
            categories:[
                PDOCategories.locationAndDirections,
                PDOCategories.patient,
                PDOCategories.remote,
                PDOCategories.translation,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteAboutVideoLinkTranslationId,
            title: 'About Video Link t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations: practiceLinkTranslations,
            translationType: TranslationType.LINK,
            categories:[
                PDOCategories.faqs,
                PDOCategories.patient,
                PDOCategories.translation,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteDisplayPaymentPortal,
            title: 'Display Payment Portal (Athena only)',
            type: ProviderDepartmentOptionDataType.checkbox,
            categories:[
                PDOCategories.payment,
                PDOCategories.patient,
                PDOCategories.visibility,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteDisplayPatientPortal,
            title: 'Display Patient Portal (Athena only)',
            type: ProviderDepartmentOptionDataType.checkbox,
            categories:[
                PDOCategories.links,
                PDOCategories.patient,
                PDOCategories.visibility,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteFaqTranslationId,
            title: 'FAQ t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations: practiceFaqTranslations,
            translationType: TranslationType.FAQ,
            categories:[
                PDOCategories.faqs,
                PDOCategories.patient,
                PDOCategories.translation,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteIntakeFormUrlTranslationId,
            title: 'Intake Form t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations: practiceLinkTranslations,
            translationType: TranslationType.LINK,
            categories:[
                PDOCategories.forms,
                PDOCategories.patient,
                PDOCategories.translation,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteShowCallButton,
            title: 'Show Call Button',
            type: ProviderDepartmentOptionDataType.checkbox,
            categories:[
                PDOCategories.contact,
                PDOCategories.patient,
                PDOCategories.visibility,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteContactTranslationId,
            title: 'Contact Info t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations: practiceContactTranslations,
            translationType: TranslationType.CONTACT,
            categories:[
                PDOCategories.contact,
                PDOCategories.patient,
                PDOCategories.translation,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteGettingToLinkATranslationId,
            title: 'Getting To Link A t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations: practiceContactTranslations,
            translationType: TranslationType.LINK,
            categories:[
                PDOCategories.patient,
                PDOCategories.translation,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteGettingToLinkBTranslationId,
            title: 'Getting To Link B t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations: practiceContactTranslations,
            translationType: TranslationType.LINK,
            categories:[
                PDOCategories.patient,
                PDOCategories.translation,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteGettingToTextContentTranslationId,
            title: 'Getting To Text Content t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations: practiceContactTranslations,
            translationType: TranslationType.CONTENT,
            categories:[
                PDOCategories.patient,
                PDOCategories.translation,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteBeforeLinkATranslationId,
            title: 'Before Link A t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations: practiceContactTranslations,
            translationType: TranslationType.LINK,
            categories:[
                PDOCategories.patient,
                PDOCategories.translation,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteBeforeLinkBTranslationId,
            title: 'Before Link B t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations: practiceContactTranslations,
            translationType: TranslationType.LINK,
            categories:[
                PDOCategories.patient,
                PDOCategories.translation,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteBeforeTextContentTranslationId,
            title: 'Before Text Content t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations: practiceContactTranslations,
            translationType: TranslationType.CONTENT,
            categories:[
                PDOCategories.patient,
                PDOCategories.translation,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteArrivedLinkATranslationId,
            title: 'Arrived Link A t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations: practiceContactTranslations,
            translationType: TranslationType.LINK,
            categories:[
                PDOCategories.patient,
                PDOCategories.translation,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteArrivedLinkBTranslationId,
            title: 'Arrived Link B t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations: practiceContactTranslations,
            translationType: TranslationType.LINK,
            categories:[
                PDOCategories.patient,
                PDOCategories.translation,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteArrivedTextContentTranslationId,
            title: 'Arrived Text Content t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations: practiceContactTranslations,
            translationType: TranslationType.CONTENT,
            categories:[
                PDOCategories.patient,
                PDOCategories.translation,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteAboutLinkATranslationId,
            title: 'About Link A t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations: practiceContactTranslations,
            translationType: TranslationType.LINK,
            categories:[
                PDOCategories.patient,
                PDOCategories.translation,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteAboutLinkBTranslationId,
            title: 'About Link B t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations: practiceContactTranslations,
            translationType: TranslationType.LINK,
            categories:[
                PDOCategories.patient,
                PDOCategories.translation,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteAboutTextContentTranslationId,
            title: 'About Text Content t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations: practiceContactTranslations,
            translationType: TranslationType.CONTENT,
            categories:[
                PDOCategories.patient,
                PDOCategories.translation,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteAfterLinkATranslationId,
            title: 'After Link A t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations: practiceContactTranslations,
            translationType: TranslationType.LINK,
            categories:[
                PDOCategories.patient,
                PDOCategories.translation,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteAfterLinkBTranslationId,
            title: 'After Link B t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations: practiceContactTranslations,
            translationType: TranslationType.LINK,
            categories:[
                PDOCategories.patient,
                PDOCategories.translation,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.patientSiteAfterTextContentTranslationId,
            title: 'After Text Content t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations: practiceContactTranslations,
            translationType: TranslationType.CONTENT,
            categories:[
                PDOCategories.patient,
                PDOCategories.translation,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.statsType,
            title: 'Appointment Delay Calc Stats Type',
            type: ProviderDepartmentOptionDataType.statsType,
            categories:[
                PDOCategories.calculations,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.persistCheckInToEhr,
            title: 'Persist Check-In to EHR',
            type: ProviderDepartmentOptionDataType.checkbox,
            practiceSlugs,
            categories:[
                PDOCategories.visibility,
                PDOCategories.patient,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.useStatsV2,
            title: 'Use V2 Stats',
            type: ProviderDepartmentOptionDataType.checkbox,
            practiceSlugs,
            categories:[
                PDOCategories.calculations,
                PDOCategories.manager,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.useMLDurations,
            title: 'Use ML Durations',
            type: ProviderDepartmentOptionDataType.checkbox,
            practiceSlugs,
            categories:[
                PDOCategories.calculations,
                PDOCategories.manager,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.appointmentPlaceholderMessageTranslationId,
            title: 'Manager Message t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations,
            translationType: TranslationType.SMS,
            categories:[
                PDOCategories.contact,
                PDOCategories.messaging,
                PDOCategories.patient,
                PDOCategories.translation,
            ],
        },
        // unused
        // {
        //     name: ProviderDepartmentOptionItem.appointmentScheduledMessageTranslationId,
        //     title: 'Use V2 Stats',
        //     type: ProviderDepartmentOptionDataType.translation,
        //     translations: globalSmsTranslations,
        //     translations: globalSmsTranslations,
        //     translationType: TranslationType.SMS,
        //     categories:[
        //         PDOCategories.contact,
        //         PDOCategories.patient,
        //         PDOCategories.translation,
        //     ],
        // },
        {
            name: ProviderDepartmentOptionItem.appointmentReminderMessageTranslationId,
            title: 'SMS - Reminder Message t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations,
            translationType: TranslationType.SMS,
            categories:[
                PDOCategories.contact,
                PDOCategories.messaging,
                PDOCategories.patient,
                PDOCategories.translation,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.appointmentDelayedMessageTranslationId,
            title: 'SMS - Delayed Message t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations,
            translationType: TranslationType.SMS,
            categories:[
                PDOCategories.contact,
                PDOCategories.messaging,
                PDOCategories.patient,
                PDOCategories.translation,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.appointmentRoomReadyMessageTranslationId,
            title: 'SMS - Room Ready Message t9n',
            type: ProviderDepartmentOptionDataType.translation,
            translations,
            translationType: TranslationType.SMS,
            categories:[
                PDOCategories.contact,
                PDOCategories.messaging,
                PDOCategories.patient,
                PDOCategories.translation,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.useRenderingProvider,
            title: 'Use Rendering Provider',
            type: ProviderDepartmentOptionDataType.checkbox,
            practiceSlugs,
            categories:[
                PDOCategories.calculations,
                PDOCategories.manager,
                PDOCategories.visibility,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.appointmentsAutoTextingDisabled,
            title: 'Disabled Auto-texts',
            type: ProviderDepartmentOptionDataType.checkbox,
            practiceSlugs,
            categories:[
                PDOCategories.patient,
                PDOCategories.contact,
                PDOCategories.messaging,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.noSmsBeforeMinutesIntoDay,
            title: 'No SMS Before Minutes Into Day',
            type: ProviderDepartmentOptionDataType.intNumber,
            practiceSlugs,
            categories:[
                PDOCategories.patient,
                PDOCategories.contact,
                PDOCategories.messaging,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.pollPatientChartsOnWaitForIntakeVitals,
            title: 'Poll patient charts for intake on vitals',

            type: ProviderDepartmentOptionDataType.checkbox,
            practiceSlugs,
            categories:[
                PDOCategories.patient,
                PDOCategories.manager,
                PDOCategories.calculations,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.pollPatientChartsOnWaitForIntakeAllergies,
            title: 'Poll patient charts for intake on allergies',
            type: ProviderDepartmentOptionDataType.checkbox,
            practiceSlugs,
            categories:[
                PDOCategories.patient,
                PDOCategories.manager,
                PDOCategories.calculations,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.pollPatientChartsOnWaitForIntakeEncounterReasons,
            title: 'Poll patient charts for intake on encounter reasons',

            type: ProviderDepartmentOptionDataType.checkbox,
            practiceSlugs,
            categories:[
                PDOCategories.patient,
                PDOCategories.manager,
                PDOCategories.calculations,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.pollPatientChartsOnWaitForIntakeProblems,
            title: 'Poll patient charts for intake on problems',

            type: ProviderDepartmentOptionDataType.checkbox,
            practiceSlugs,
            categories:[
                PDOCategories.patient,
                PDOCategories.manager,
                PDOCategories.calculations,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.pollPatientChartsOnWaitForDoctorHpi,
            title: 'Poll encounter charts for exam start time on HPI',
            type: ProviderDepartmentOptionDataType.checkbox,
            practiceSlugs,
            categories:[
                PDOCategories.patient,
                PDOCategories.calculations,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.pollPatientChartsOnWaitForDoctorOrders,
            title: 'Poll encounter charts for exam start time on orders',
            type: ProviderDepartmentOptionDataType.checkbox,
            practiceSlugs,
            categories:[
                PDOCategories.patient,
                PDOCategories.calculations,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.pollPatientChartsOnWaitForDoctorEncounterReasons,
            title: 'Poll encounter charts for exam start time on encounter reasons',
            type: ProviderDepartmentOptionDataType.checkbox,
            practiceSlugs,
            categories:[
                PDOCategories.patient,
                PDOCategories.calculations,
            ],
        },{
            name: ProviderDepartmentOptionItem.pollPatientChartsOnWaitForDoctorReviewOfSystem,
            title: 'Poll encounter charts for exam start time on review of system',
            type: ProviderDepartmentOptionDataType.checkbox,
            practiceSlugs,
            categories:[
                PDOCategories.patient,
                PDOCategories.calculations,
            ],
        },

        {
            name: ProviderDepartmentOptionItem.pollPatientChartsOnExamDiagnosis,
            title: 'Poll encounter charts for exam end time on diagnosis',
            type: ProviderDepartmentOptionDataType.checkbox,
            practiceSlugs,
            categories:[
                PDOCategories.patient,
                PDOCategories.calculations,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.canRollbackStatus,
            title: 'Appt. status can be rolled back',
            type: ProviderDepartmentOptionDataType.checkbox,
            practiceSlugs,
            categories:[
                PDOCategories.calculations,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.useBetaOverbookedStackingBehavior,
            title: 'Use Beta Overbooked Appointment Stacking Behavior',
            type: ProviderDepartmentOptionDataType.checkbox,
            practiceSlugs,
            categories:[
                PDOCategories.manager,
                PDOCategories.calculations,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.useStatusInferenceByLocation,
            title: 'Use Patient Location to infer appointment status',
            type: ProviderDepartmentOptionDataType.checkbox,
            practiceSlugs,
            categories:[
                PDOCategories.manager,
                PDOCategories.calculations,
            ],
        },
        {
            name: ProviderDepartmentOptionItem.preventSatBeforeDepartmentOpenHour,
            title: 'Prevent SAT from being before department open',
            type: ProviderDepartmentOptionDataType.checkbox,
            practiceSlugs,
            categories:[
                PDOCategories.manager,
                PDOCategories.calculations,
                PDOCategories.messaging,
            ],
        },
    ]
}