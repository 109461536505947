import React, { FC, useState, useCallback } from 'react'
import Link from 'next/link'
import { find, filter, includes, keys } from 'lodash'
import { useRouter, useSearchParams, usePathname } from 'next/navigation'
import clsx from 'clsx'
import FuzzySearch from 'fuzzy-search'
import { useAtom } from 'jotai'

import { CloseCircle } from '@docpace/shared-react-icons'
import adminRoutes from '@docpace/shared-react-routes/adminRoutes'
import { StarFilledIcon, StarOutlineIcon } from '@docpace/shared-react-icons'
import { useAdminBase } from '@docpace/admin-react-hooks'
import { useAdminPracticeBase } from '@docpace/admin-react-hooks'
import { makeAppointmentStatusCountToday } from '@docpace/shared-ts-utils'
import { ctxPracticeIdAtom } from '@docpace/shared-react-atoms'
import { useFavorites } from '@docpace/shared-react-hooks'

export interface AdminHeaderSearchNextProps { }
enum filterOptions {
    'all',
    'practices',
    'providers',
    'appointmentTypes',
    'departments',
}

export const AdminHeaderSearchNext: FC<AdminHeaderSearchNextProps> = (props) => {
    const searchParams = useSearchParams()
    const pathname = usePathname()
    const [search, setSearch] = useState<string>('')
    const [forceClosed, setForceClosed] = useState<boolean>(false)
    const [searchFilter, setSearchFilter] = useState<filterOptions>(
        filterOptions.all
    )

    const [practiceId] = useAtom(ctxPracticeIdAtom)

    const {
        adminId,
        practices,
        isLoading: adminBaseQueryLoading,
    } = useAdminBase()

    const adminFavorites = useFavorites()

    const {
        favoritePracticeIds,
        favoriteProviderIds,
        favoriteDepartmentIds,
        favoriteAppointmentTypeIds,
    } = adminFavorites ?? {}
    
    const { isLoading, practice, providers, departments, appointmentTypes } =
        useAdminPracticeBase()

    const isAnythingLoading =
        isLoading || adminBaseQueryLoading || adminFavorites.loading

    const hasSearch = search?.length > 0

    const practiceSearcher = new FuzzySearch(practices, [
        'practiceId',
        'name',
        'address.city',
        'address.state',
        'address.zipCode',
        'address.address1',
        'address.address2',
    ])
    const filteredPractices = hasSearch
        ? practiceSearcher.search(search)
        : filter(practices, ({ practiceId }) =>
            includes(favoritePracticeIds, practiceId)
        )

    const providerSearcher = new FuzzySearch(providers, [
        'providerId',
        'displayName',
        'firstName',
        'lastName',
    ])
    const filteredProviders = hasSearch
        ? providerSearcher.search(search)
        : filter(providers, ({ providerId }) =>
            includes(favoriteProviderIds, providerId)
        )

    const departmentSearcher = new FuzzySearch(departments, [
        'departmentId',
        'name',
        'publicName',
        'address.addressString',
    ])
    const filteredDepartments = hasSearch
        ? departmentSearcher.search(search)
        : filter(departments, ({ departmentId }) =>
            includes(favoriteDepartmentIds, departmentId)
        )

    const appointmentTypeSearcher = new FuzzySearch(appointmentTypes, [
        'appointmentTypeId',
        'displayName',
        'name',
        'shortName',
    ])
    const filteredAppointmentTypes = hasSearch
        ? appointmentTypeSearcher.search(search)
        : filter(appointmentTypes, ({ appointmentTypeId }) =>
            includes(favoriteAppointmentTypeIds, appointmentTypeId)
        )

    const handleListItemClick = () => {
        setSearch('')
        setForceClosed(true)
    }

    // const createQueryString = useCallback(
    //     (name: string, value: string) => {
    //         const params = new URLSearchParams(searchParams.toString())
    //         params.set(name, value)

    //         return params.toString()
    //     },
    //     [searchParams]
    // )

    return (
        <div className="flex items-center">
            <div
                className={clsx(
                    'dropdown dropdown-end',
                    search?.length > 0 && !setForceClosed && 'dropdown-open'
                )}
            >
                <div className="flex items-center pr-1">
                    <input
                        value={search || ''}
                        type="text"
                        placeholder="search"
                        className="input input-bordered input-sm text-black dark:text-gray-50 w-48"
                        onChange={(ev) => setSearch(ev.target.value)}
                        onFocus={() => setForceClosed(false)}
                    />
                </div>
                {!forceClosed && (
                    <ul
                        tabIndex={0}
                        className="bg-white dark:bg-gray-700 menu dropdown-content mt-2 overflow-scroll rounded-md shadow-lg "
                        style={{
                            width: '70vw',
                            maxWidth: 800,
                            maxHeight: '80vh',
                        }}
                    >
                        <li className="">
                            <div
                                className="card w-full py-2"
                                style={{ height: '100%' }}
                            >
                                <div className="card-body p-0 pt-2">
                                    {isAnythingLoading && (
                                        <div className="card-title p-1">
                                            Loading...
                                        </div>
                                    )}
                                    {!isAnythingLoading && (
                                        <>
                                            <div className="flex justify-end mr-2 absolute top-2 right-0">
                                                <div className="btn-group relative">
                                                    <button
                                                        children="All"
                                                        onClick={() =>
                                                            setSearchFilter(
                                                                filterOptions.all
                                                            )
                                                        }
                                                        className={clsx(
                                                            searchFilter ===
                                                            filterOptions.all &&
                                                            'btn-active',
                                                            'btn btn-sm text-sm bg-gray-100 mx-1 btn-outline dark:bg-gray-400'
                                                        )}
                                                    />
                                                    <button
                                                        children="Providers"
                                                        onClick={() =>
                                                            setSearchFilter(
                                                                filterOptions.providers
                                                            )
                                                        }
                                                        className={clsx(
                                                            searchFilter ===
                                                            filterOptions.providers &&
                                                            'btn-active',
                                                            'btn btn-sm text-sm bg-gray-100 mx-1 btn-outline dark:bg-gray-400'
                                                        )}
                                                    />
                                                    <button
                                                        children="Departments"
                                                        onClick={() =>
                                                            setSearchFilter(
                                                                filterOptions.departments
                                                            )
                                                        }
                                                        className={clsx(
                                                            searchFilter ===
                                                            filterOptions.departments &&
                                                            'btn-active',
                                                            'btn btn-sm text-sm bg-gray-100 mx-1 btn-outline dark:bg-gray-400'
                                                        )}
                                                    />
                                                    <button
                                                        children="Practices"
                                                        onClick={() =>
                                                            setSearchFilter(
                                                                filterOptions.practices
                                                            )
                                                        }
                                                        className={clsx(
                                                            searchFilter ===
                                                            filterOptions.practices &&
                                                            'btn-active',
                                                            'btn btn-sm text-sm bg-gray-100 mx-1 btn-outline dark:bg-gray-400'
                                                        )}
                                                    />
                                                    <button
                                                        children="Appt Types"
                                                        onClick={() =>
                                                            setSearchFilter(
                                                                filterOptions.appointmentTypes
                                                            )
                                                        }
                                                        className={clsx(
                                                            searchFilter ===
                                                            filterOptions.appointmentTypes &&
                                                            'btn-active',
                                                            'btn btn-sm text-sm bg-gray-100 mx-1 btn-outline dark:bg-gray-400'
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            {includes(
                                                [
                                                    filterOptions.all,
                                                    filterOptions.providers,
                                                ],
                                                searchFilter
                                            ) && (
                                                    <>
                                                        <div className="card-title text-lg pl-3 mb-1">
                                                            Practice Providers
                                                        </div>
                                                        <ul
                                                            className="mb-1"
                                                            style={{ padding: 0 }}
                                                        >
                                                            {filteredProviders.length ===
                                                                0 && (
                                                                    <div className="ml-5 my-2 opacity-50 text-sm">
                                                                        {hasSearch
                                                                            ? 'No Results'
                                                                            : 'No Favorites (enter search text to search for all practice providers)'}
                                                                    </div>
                                                                )}
                                                            {filteredProviders.map(
                                                                ({
                                                                    providerId,
                                                                    practiceId,
                                                                    displayName,
                                                                }) => {
                                                                    const href =
                                                                        searchParams.get('practiceId') &&
                                                                            searchParams.get('providerId')
                                                                            ? pathname + '?' + new URLSearchParams(({ 
                                                                                    ...searchParams,
                                                                                    practiceId,
                                                                                    providerId,
                                                                                }).toString())
                                                                            : adminRoutes.ProviderDetail.hrefNext(
                                                                                {
                                                                                    practiceId,
                                                                                    providerId,
                                                                                }
                                                                            )

                                                                    return (
                                                                        <li
                                                                            key={
                                                                                providerId
                                                                            }
                                                                            onClick={
                                                                                handleListItemClick
                                                                            }
                                                                        >
                                                                            <Link className="hover:underline"
                                                                                href={{
                                                                                    href
                                                                                }}
                                                                            >
                                                                                <div className="flex justify-between flex-1">
                                                                                    <div className="flex align-center">
                                                                                        <div className="w-3 mr-3 pt-1">
                                                                                            {includes(
                                                                                                favoriteProviderIds,
                                                                                                providerId
                                                                                            ) && (
                                                                                                    <StarFilledIcon />
                                                                                                )}
                                                                                        </div>
                                                                                        {
                                                                                            displayName
                                                                                        }
                                                                                    </div>
                                                                                    <div className="text-sm text-right align-right">
                                                                                        <div>
                                                                                            {`${makeAppointmentStatusCountToday({
                                                                                                manager: null,
                                                                                                provider: find(
                                                                                                    providers,
                                                                                                    {
                                                                                                        providerId,
                                                                                                    }
                                                                                                ),
                                                                                                isAdminSite: true
                                                                                            })} appointments today`}
                                                                                        </div>
                                                                                        <div>{`(${providerId})`}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                        </li>
                                                                    )
                                                                }
                                                            )}
                                                        </ul>
                                                    </>
                                                )}

                                            {includes(
                                                [
                                                    filterOptions.all,
                                                    filterOptions.departments,
                                                ],
                                                searchFilter
                                            ) && (
                                                    <>
                                                        <div className="card-title text-lg pl-3 mb-1">
                                                            Practice Departments
                                                        </div>
                                                        <ul
                                                            className="mb-1"
                                                            style={{ padding: 0 }}
                                                        >
                                                            {filteredDepartments.length ===
                                                                0 && (
                                                                    <div className="ml-5 my-2 opacity-50 text-sm">
                                                                        {hasSearch
                                                                            ? 'No Results'
                                                                            : 'No Favorites (enter search text to search for all practice departments)'}
                                                                    </div>
                                                                )}
                                                            {filteredDepartments.map(
                                                                ({
                                                                    departmentId,
                                                                    practiceId,
                                                                    publicName,
                                                                    address,
                                                                }) => {
                                                                    const href =
                                                                        searchParams.get('practiceId') &&
                                                                        searchParams.get('providerId')
                                                                        ? {
                                                                            pathname: pathname + '?' + new URLSearchParams(({ 
                                                                                ...searchParams,
                                                                                practiceId,
                                                                                departmentId,
                                                                            }).toString())
                                                                } : adminRoutes.DepartmentDetail.hrefNext({
                                                                    practiceId,
                                                                    departmentId,
                                                                })

                                                            return (
                                                            <li
                                                                key={
                                                                    departmentId
                                                                }
                                                                onClick={
                                                                    handleListItemClick
                                                                }
                                                            >
                                                                <Link className="hover:underline"
                                                                    href={
                                                                        href
                                                                    }
                                                                >
                                                                    <div className="flex justify-between flex-1">
                                                                        <div className="flex align-center">
                                                                            <div className="w-3 mr-3 pt-1">
                                                                                {includes(
                                                                                    filteredDepartments,
                                                                                    departmentId
                                                                                ) && (
                                                                                        <StarFilledIcon />
                                                                                    )}
                                                                            </div>
                                                                            {
                                                                                publicName
                                                                            }
                                                                        </div>
                                                                        <div className="text-sm text-right align-right">
                                                                            <div>
                                                                                {
                                                                                    address?.addressString
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                            )
                                                            }
                                                        )}
                                                        </ul>
                                                    </>
                                                )}

                                            {includes(
                                                [
                                                    filterOptions.all,
                                                    filterOptions.appointmentTypes,
                                                ],
                                                searchFilter
                                            ) && (
                                                    <>
                                                        <div className="card-title text-lg pl-3 mb-1">
                                                            Practice Appointment
                                                            Types
                                                        </div>
                                                        <ul
                                                            className="mb-1"
                                                            style={{ padding: 0 }}
                                                        >
                                                            {filteredAppointmentTypes.length ===
                                                                0 && (
                                                                    <div className="ml-5 my-2 opacity-50 text-sm">
                                                                        {hasSearch
                                                                            ? 'No Results'
                                                                            : 'No Favorites (enter search text to search for all appointment types)'}
                                                                    </div>
                                                                )}
                                                            {filteredAppointmentTypes.map(
                                                                ({
                                                                    appointmentTypeId,
                                                                    name,
                                                                    displayName,
                                                                }) => {
                                                                    const href =
                                                                        searchParams.get('appointmentTypeId') // just update the query param if it already exists in the current page url otherwise go to appt type detail page
                                                                            ? {
                                                                                pathname: pathname + '?' + new URLSearchParams(({ 
                                                                                    ...searchParams,
                                                                                    practiceId,
                                                                                    appointmentTypeId,
                                                                                }).toString())
                                                                            }
                                                                            : adminRoutes.PracticeAppointmentTypeDetail.hrefNext(
                                                                                {
                                                                                    practiceId,
                                                                                    appointmentTypeId,
                                                                                }
                                                                            )

                                                                    return (
                                                                        <li
                                                                            key={
                                                                                appointmentTypeId
                                                                            }
                                                                            onClick={
                                                                                handleListItemClick
                                                                            }
                                                                        >
                                                                            <Link className="hover:underline"
                                                                                href={
                                                                                    href
                                                                                }
                                                                            >
                                                                                <div className="flex justify-between flex-1">
                                                                                    <div className="flex align-center">
                                                                                        <div className="w-3 mr-3 pt-1">
                                                                                            {includes(
                                                                                                favoriteAppointmentTypeIds,
                                                                                                appointmentTypeId
                                                                                            ) && (
                                                                                                    <StarFilledIcon />
                                                                                                )}
                                                                                        </div>
                                                                                        {
                                                                                            name
                                                                                        }
                                                                                    </div>
                                                                                    <div className="text-sm">
                                                                                        (
                                                                                        {
                                                                                            appointmentTypeId
                                                                                        }

                                                                                        )
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                        </li>
                                                                    )
                                                                }
                                                            )}
                                                        </ul>
                                                    </>
                                                )}

                                            {includes(
                                                [
                                                    filterOptions.all,
                                                    filterOptions.practices,
                                                ],
                                                searchFilter
                                            ) && (
                                                    <>
                                                        <div className="card-title text-lg pl-3 mb-1">
                                                            Practices
                                                        </div>
                                                        <ul
                                                            className="mb-1"
                                                            style={{ padding: 0 }}
                                                        >
                                                            {filteredPractices.length ===
                                                                0 && (
                                                                    <div className="ml-5 my-2 opacity-50 text-sm">
                                                                        {hasSearch
                                                                            ? 'No Results'
                                                                            : 'No Favorites (enter search text to search for all practices)'}
                                                                    </div>
                                                                )}
                                                            {filteredPractices.map(
                                                                ({
                                                                    practiceId,
                                                                    name,
                                                                }) => {
                                                                    const href =
                                                                        searchParams.get('practiceId') &&
                                                                            !searchParams.get('providerId') &&
                                                                            !searchParams.get('departmentId') &&
                                                                            !searchParams.get('managerId')
                                                                            ? {
                                                                                pathname: pathname + '?' + new URLSearchParams(({ 
                                                                                    ...searchParams,
                                                                                    practiceId,
                                                                                }).toString())
                                                                            }
                                                                            : adminRoutes.PracticeHome.hrefNext(
                                                                                {
                                                                                    practiceId,
                                                                                }
                                                                            )

                                                                    return (
                                                                        <li
                                                                            key={
                                                                                practiceId
                                                                            }
                                                                            onClick={
                                                                                handleListItemClick
                                                                            }
                                                                        >
                                                                            <Link className="hover:underline"
                                                                                href={
                                                                                    href
                                                                                }
                                                                            >
                                                                                <div className="flex justify-between flex-1">
                                                                                    <div className="flex align-center">
                                                                                        <div className="w-3 mr-3 pt-1">
                                                                                            {includes(
                                                                                                favoritePracticeIds,
                                                                                                practiceId
                                                                                            ) && (
                                                                                                    <StarFilledIcon />
                                                                                                )}
                                                                                        </div>
                                                                                        {
                                                                                            name
                                                                                        }
                                                                                    </div>
                                                                                    <div className="text-sm">
                                                                                        (
                                                                                        {
                                                                                            practiceId
                                                                                        }

                                                                                        )
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                        </li>
                                                                    )
                                                                }
                                                            )}
                                                        </ul>
                                                    </>
                                                )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </li>
                    </ul>
                )}
            </div>
            <div className={'w-4'} style={{ position: 'relative', top: 3 }}>
                {search?.length > 0 && (
                    <button
                        onClick={handleListItemClick}
                        children={
                            <CloseCircle className="text-white h-5 w-5" />
                        }
                    />
                )}
            </div>
        </div>
    )
}
