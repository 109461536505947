import React from 'react'
import { useSearchParams, useRouter, useParams } from 'next/navigation'
import { get, isString, toString, isInteger, toInteger } from 'lodash'

export const useNextQueryParamNext: (
    key: string,
    defaultValue?: any
) => string | null = (key, defaultValue) => {
    const searchParams = useSearchParams()
    const urlParams = useParams()
    let param = urlParams?.[key] ?? searchParams.get(key)

    param = (isString(param) ? param : param?.[0]) ?? null
    if (!param || param === '')
        return typeof defaultValue !== 'undefined'
            ? toString(defaultValue)
            : null
    return param
}
