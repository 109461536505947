import React, { FC, useEffect } from 'react'
import { LinkProps } from 'next/link'

import clsx from 'clsx'
import Link from 'next/link'
import { useTheme } from '@material-ui/core/styles'
import {
    List,
    Drawer,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core'

import { useAdminSidebarStyles } from '@docpace/admin-react-components'
import adminRoutes from '@docpace/shared-react-routes/adminRoutes'
import { Divider } from '@docpace/shared-react-components/Divider'
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    PushpinPinnedIcon,
    PushpinUnpinnedIcon,
} from '@docpace/shared-react-icons'

export interface AdminSidebarNextProps {
    practiceId?: string
    providerId?: string
    
    isOpen: boolean
    isPinned: boolean
    isHovering?: boolean
    setIsSidebarPinned: (isSidebarPinned: boolean) => Promise<void>
    setIsSidebarOpen: (isSidebarOpen: boolean) => void
}

export interface AdminSidebarNextLinkProps {
    title: string
    id: string
    Icon?: any
    linkProps?: LinkProps
    hideIcon?: boolean
    disabled?: boolean
    dense?: boolean
    endIcon?: any
    onClick?: any
}

const MuiSidebarNavListIcon = React.memo(
    Object.assign(
        (props: any) => {
            const { linkProps, disabled, Icon } = props
            const iconComponent = (
                <div className={'w-6 m-0 mr-2 text-gray-600 dark:text-gray-50'}>
                    <Icon />
                </div>
            )
            return linkProps && !disabled ? (
                <Link {...linkProps}>
                    {iconComponent}
                </Link>
            ) : (
                iconComponent
            )
        },
        { displayName: 'MuiSidebarNavListIcon' }
    )
)

const MuiSidebarNavListItemText = React.memo(
    Object.assign(
        (props: any) => {
            const { title, linkProps, disabled } = props
            const titleComponent = (
                <ListItemText
                    primary={title}
                    className={
                        'mx-0 my-4 font-sm text-gray-600 dark:text-gray-50'
                    }
                />
            )
            return linkProps && !disabled ? (
                <Link {...linkProps}>
                    {titleComponent}
                </Link>
            ) : (
                titleComponent
            )
        },
        { displayName: 'MuiSidebarNavListItemText' }
    )
)

export const AdminSidebarNextLink: FC<AdminSidebarNextLinkProps> = (props) => {
    const {
        linkProps,
        title,
        id,
        Icon,
        hideIcon,
        disabled,
        dense,
        endIcon,
        onClick,
    } = props
    return (
        <ListItem
            onClick={onClick}
            key={title}
            id={id}
            className={clsx('h-10 cursor-pointer', hideIcon && 'm-0 p-0')}
            disabled={disabled}
            dense={dense}
        >
            {Icon && <MuiSidebarNavListIcon {...props} />}
            <MuiSidebarNavListItemText {...props} />
            {endIcon}
        </ListItem>
    )
}

export const AdminSidebarNext: FC<AdminSidebarNextProps> = (props) => {
    const {
        practiceId,
        providerId,
        isPinned,
        isOpen,
        isHovering,
        setIsSidebarOpen,
        setIsSidebarPinned,
    } = props
    const classes = useAdminSidebarStyles()
    const theme = useTheme()

    const MenuTrayIcon = () => {
        const isLtr = theme.direction === 'ltr'
        const isChevronLeft = !(isLtr && isOpen) && (isLtr || isOpen)
        return (
            <IconButton
                id='sidebar-tray-button'
                onClick={() => setIsSidebarOpen(!isOpen)}
                className={classes.closeIcon}
            >
                {isChevronLeft ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
        )
    }

    const PushpinIcon = () => {
        const style = { fontSize: '0.9rem', right: 5 }
        return (
            <IconButton className={classes.closeIcon}>
                {isPinned ? (
                    <PushpinPinnedIcon style={style} />
                ) : (
                    <PushpinUnpinnedIcon style={style} />
                )}
            </IconButton>
        )
    }

    useEffect(() => {
        !isPinned && setIsSidebarOpen(!!isHovering)
    }, [isHovering, isPinned])

    if (!practiceId) return <></>

    return (
        <Drawer
            id={'admin-sidebar'}
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: isOpen,
                [classes.drawerClose]: !isOpen,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: isOpen,
                    [classes.drawerClose]: !isOpen,
                }),
            }}
        >
            <List style={{ paddingTop: 0 }}>
                <div className={classes.toolbar}>
                    <MenuTrayIcon />
                </div>
                <Divider />
                <AdminSidebarNextLink
                    title="Practice"
                    id='sidebar-practice-link'
                    linkProps={{
                        href: adminRoutes.PracticeHome.hrefNext({ practiceId }),
                    }}
                    Icon={adminRoutes.PracticeHome.icon}
                />
                <AdminSidebarNextLink
                    title="Appointment Types"
                    id='sidebar-appointment-types-link'
                    linkProps={{
                        href: adminRoutes.PracticeAppointmentTypes.hrefNext({
                            practiceId,
                        }),
                    }}
                    Icon={adminRoutes.PracticeAppointmentTypes.icon}
                />
                <AdminSidebarNextLink
                    title="Departments"
                    id='sidebar-departments-link'
                    linkProps={{
                        href: adminRoutes.PracticeDepartments.hrefNext({
                            practiceId,
                        }),
                    }}
                    Icon={adminRoutes.PracticeDepartments.icon}
                />
                <AdminSidebarNextLink
                    title="Providers"
                    id='sidebar-providers-link'
                    linkProps={{
                        href: adminRoutes.PracticeProviders.hrefNext({
                            practiceId,
                        }),
                    }}
                    Icon={adminRoutes.PracticeProviders.icon}
                />
                {/* <AdminSidebarNextLink disabled title='Devices' linkProps={{ href: adminRoutes.PracticeDevices.hrefNext({ practiceId }) }} Icon={adminRoutes.PracticeDevices.icon}/> */}
                <AdminSidebarNextLink
                    title="Users"
                    id='sidebar-managers-link'
                    linkProps={{
                        href: adminRoutes.PracticeManagers.hrefNext({ practiceId }),
                    }}
                    Icon={adminRoutes.PracticeManagers.icon}
                />
                <AdminSidebarNextLink
                    title="Translations"
                    id='sidebar-translations-link'
                    linkProps={{
                        href: adminRoutes.PracticeTranslations.hrefNext({
                            practiceId,
                        }),
                    }}
                    Icon={adminRoutes.PracticeTranslations.icon}
                />
                <AdminSidebarNextLink
                    title="Reports"
                    id='sidebar-reports-link'
                    linkProps={{
                        href: adminRoutes.PracticeReportsHome.hrefNext({
                            practiceId,
                        }),
                    }}
                    Icon={adminRoutes.PracticeReportsHome.icon}
                />
                <AdminSidebarNextLink
                    title="Configure"
                    id='sidebar-configure-link'
                    linkProps={{
                        href: adminRoutes.PracticeConfigure.hrefNext({
                            practiceId,
                        }),
                    }}
                    Icon={adminRoutes.PracticeConfigure.icon}
                />
            </List>
            <List className='flex flex-1 flex-col justify-end'>
                <div style={{ position: 'relative', right: 2 }}>
                    <AdminSidebarNextLink
                        title={`${isPinned ? 'Unpin' : 'Pin'} Sidebar`}
                        id='sidebar-pushpin-button'
                        Icon={PushpinIcon}
                        onClick={() => setIsSidebarPinned(!isPinned)}
                    />
                </div>
            </List>
        </Drawer>
    )
}
