export enum TranslationType {
    VWR = 'VWR',
    SMS = 'SMS',
    FAQ = 'FAQ',
    DIRECTIONS = 'DIRECTIONS',
    CONTACT = 'CONTACT',
    LINK = 'LINK',
    CONTENT = 'CONTENT'
}

export enum TranslationLanguage {
    eng = 'eng',
    spa = 'spa',
    por = 'por',
}

export interface TranslationContentConstant {
    eng: string
    spa?: string
    zho?: string // this is not implemented in our constants and is only used for testing purposes
}