'use client'
import { FC, useEffect } from 'react'
import ReactHoverObserver from 'react-hover-observer'
import Link from 'next/link'
import Head from 'next/head'
import { usePathname, useRouter } from 'next/navigation'
import { Dialog } from '@docpace/shared-react-components/Dialog'
import { useAtom } from 'jotai'


import '@docpace/apps/admin-app/tailwind.css'
import '@docpace/shared-react-styles/shared/globals.css'
import '@docpace/shared-react-styles/shared/materialUI.css'
import '@docpace/shared-react-styles/shared/cards.css'
import '@docpace/shared-react-styles/shared/tables.css'
import '@docpace/shared-react-styles/shared/layout.css'
import 'react-datetime/css/react-datetime.css'

import {
    ADMIN_SIDEBAR_DRAWER_WIDTH,
    ADMIN_SIDEBAR_DRAWER_WIDTH_CLOSED,
} from '@docpace/admin-react-components/AdminLayout'
import {
    AdminHeaderNext,
} from '@docpace/admin-react-components/AdminHeaderNext'
import {
    AdminSidebarNext,
} from '@docpace/admin-react-components/AdminSidebarNext'
import {
    AdminLayoutNav,
} from '@docpace/admin-react-components/AdminLayoutNav'
import adminRoutes from '@docpace/shared-react-routes/adminRoutes'
import { useNextQueryParamNext } from '@docpace/shared-react-hooks/useNextQueryParamNext'
import { useAdminPracticeBase } from '@docpace/admin-react-hooks'
import { useAdminDashboardSettingsNext } from '@docpace/admin-react-hooks'
import { ctxPracticeIdAtom, ctxIsSessionInitialized, ctxIsSidebarOpen, ctxSelectedPdoIds, ctxPdoItem, ctxIsLoggingOut, adminAtomsPracticeReset, sharedAtomsPracticeReset, sharedUiAtomsPracticeReset } from '@docpace/shared-react-atoms'

import { useAdminBase } from '@docpace/admin-react-hooks'
import { AdminTableBorderedRowStyle } from '@docpace/admin-react-lists/AdminList'
import { AdminProviderDepartmentOptionForm } from '../AdminProviderDepartmentOptionForm/AdminProviderDepartmentOptionForm'
import { useAdminAdminDetailQuery, useContextAdminBaseQuery } from '@docpace/admin-react-apollo'
import { useManagerOverviewQuery } from '@docpace/manager-react-apollo'


export interface AdminLayoutNextProps {
    children?: any
    navChildren?: any
    isChangingRoute?: boolean
    makeNav?: () => any[]
    title?: string | (() => any)
    subtitle?: string | (() => any)
}

export const AdminLayoutNext: FC<AdminLayoutNextProps> = (props) => {
    const [ practiceId, setCtxPracticeId ] = useAtom(ctxPracticeIdAtom)
    const queryPracticeId = useNextQueryParamNext('practiceId')
    useEffect(()=>{
        setCtxPracticeId(queryPracticeId)
    }, [ queryPracticeId ])
    const [ isInitialized ] = useAtom(ctxIsSessionInitialized)
    const [ selectedPdoConfig ] = useAtom(ctxPdoItem)
    const [[ configIds, ...restIds ], setSelectedPdoIds ] = useAtom(ctxSelectedPdoIds)
    const router = useRouter()
    const pathname = usePathname()
    const {
        children,
        title,
        subtitle,
        isChangingRoute,
        navChildren,
    } = props
    const isAuthPage = pathname?.split('/')?.[1] === 'auth'
    const { isProd } = useAdminBase({
        skip: isAuthPage || !isInitialized,
    })
    useContextAdminBaseQuery({ isAuthPage })
    const providerId = useNextQueryParamNext('providerId')
    const departmentId = useNextQueryParamNext('departmentId')
    const managerId = useNextQueryParamNext('managerId')
    const adminId = useNextQueryParamNext('adminId')
    const [ isSidebarOpen, setIsSidebarOpen ] = useAtom(ctxIsSidebarOpen)
    const [ isLoggingOut, setIsLoggingOut ] = useAtom(ctxIsLoggingOut)

    const { practice, isLoading } = useAdminPracticeBase({
        skip: isAuthPage || !isInitialized,
    })

    const managerDetailQuery = useManagerOverviewQuery({
        variables: { managerId: managerId ?? '' },
        skip: !managerId || isAuthPage || !isInitialized,
    })

    const adminDetailQuery = useAdminAdminDetailQuery({
        variables: { adminId: adminId ?? '' },
        skip: !adminId || isAuthPage || !isInitialized,
    })

    const { adminDashboardSettings, updateAdminDashboardSettings, actor } =
        useAdminDashboardSettingsNext({ skipQueries: !isInitialized || isAuthPage })

    const isSidebarPinned = adminDashboardSettings?.['sidebarPinned'] ?? false

    const isAnythingLoading =
        isLoading || managerDetailQuery.loading || adminDetailQuery.loading

    const sidebarMargin = practiceId
        ? isSidebarPinned && isSidebarOpen
            ? ADMIN_SIDEBAR_DRAWER_WIDTH
            : ADMIN_SIDEBAR_DRAWER_WIDTH_CLOSED
        : 0

    const setIsSidebarPinned = async (isPinned: boolean) => {
        await updateAdminDashboardSettings('sidebarPinned', isPinned)
    }

    const handleLogout = () => {
        setIsLoggingOut(true)
        router.push(adminRoutes.Signout.hrefNext())
    }

    useAtom(adminAtomsPracticeReset)
    useAtom(sharedAtomsPracticeReset)
    useAtom(sharedUiAtomsPracticeReset)

    return (
        <div style={{ width: '100vw' }}>
            <Head>
                <title>DOCPACE® Admin</title>
                <meta
                    name="viewport"
                    content="initial-scale=1.0, width=device-width"
                />
            </Head>

            {practiceId && (
                <ReactHoverObserver>
                    <AdminSidebarNext
                        practiceId={practiceId}
                        providerId={providerId}
                        isOpen={isSidebarOpen}
                        isPinned={isSidebarPinned}
                        setIsSidebarPinned={setIsSidebarPinned}
                        setIsSidebarOpen={setIsSidebarOpen}
                    />
                </ReactHoverObserver>
            )}

            <div
                style={{
                    width: `calc(100vw - ${sidebarMargin}px)`,
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: sidebarMargin,
                }}
            >
                <AdminHeaderNext
                    isAuthPage={isAuthPage}
                    subtitle={
                        typeof subtitle === 'function'
                            ? subtitle()
                            : subtitle ?? (
                                <div>
                                    {!practiceId && (
                                        <div className="cursor-pointer hover:none">
                                            Admin Dashboard
                                        </div>
                                    )}
                                    {practiceId && (
                                        <Link className="cursor-pointer hover:underline"
                                            href={
                                                practiceId
                                                    ? adminRoutes.PracticeHome.hrefNext(
                                                        { practiceId }
                                                    )
                                                    : adminRoutes.PracticeHome.hrefNext()
                                            }
                                        >
                                        {(practice?.name ?? '') +
                                            ' Admin Dashboard'}
                                        </Link>
                                    )}
                                </div>
                              )
                    }
                    title={typeof title === 'function' ? title() : title}
                    handleLogout={handleLogout}
                    isProd={isProd}
                />
                {isChangingRoute && (
                    <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
                        <span
                            className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0"
                            style={{
                                top: '50%',
                            }}
                        >
                            <i className="fas fa-circle-notch fa-spin fa-5x"></i>
                        </span>
                    </div>
                )}
                <div className={'flex flex-col scroll flex-1'}>
                    <AdminLayoutNav
                        {...props}
                        loading={isAnythingLoading}
                        children={navChildren}
                    />
                    {children}
                </div>
                <AdminTableBorderedRowStyle />
                <Dialog
                    open={!!configIds && !!selectedPdoConfig}
                    onClose={() => setSelectedPdoIds([])}
                    maxWidth='md'
                    fullWidth
                >
                    <div className="p-2 bg-white w-full">
                        <AdminProviderDepartmentOptionForm />
                    </div>
                </Dialog>
            </div>
        </div>
    )
}
